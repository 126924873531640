.destino {
    width: 100%;
    margin-bottom: 2em;
}


.carrusel-destinos {
    width: 100%;
}

.imagen-carrusel-destinos {
    width: 85%;
    object-fit: cover;
    height: 70vh;
    margin: auto;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    margin: 1em auto;
}

.destino-h3 {
    text-align: center;
    margin-top: 1em;
    color: #00C8C8 !important;
    padding-top: .8em;
}

.destino-p {
    padding: 0em 2em;
    font-size: medium;
    text-align: justify;
}

.destino-a {
    display: flex;
    justify-content: center;
    font-size: larger;
    margin-bottom: 3em;
    padding: 1.2em;

}

.turismo-h3 {
    text-align: center;
    margin-top: 1em;

}

.icono-ubicacion {
    margin-right: .2em;
}

@media (max-width: 575px) {


    .imagen-carrusel-destinos {
        width: 90%;
        height: 50vh;

    }

}