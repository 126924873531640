/* footer.css */

.footer {
    background-color: #24B3A1; /* Color principal de la marca */
    color: #ffffff; /* Color blanco para el texto */
    padding: 30px 20px;
    text-align: center;
    font-size: 14px;
    position: relative;
  }
  
  .footer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .footer-left, .footer-center, .footer-right {
    flex: 1;
    min-width: 200px;
    margin: 10px;
  }
  
  .footer-links {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: center;
  }
  
  .footer-links li {
    margin: 8px 0;
  }
  
  .footer-links a {
    color: #ffffff; /* Color blanco para los enlaces */
    text-decoration: none;
    font-weight: 400;
  }
  
  .footer-links a:hover {
    color: #ffffff;
    text-decoration: underline; /* Subrayado al pasar el cursor */
  }
  
  .footer-social {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
  }
  
  .footer-social li {
    margin: 0 15px;
  }
  
  .footer-social a {
    color: #ffffff; /* Color blanco para los íconos sociales */
    text-decoration: none;
    font-size: 24px;
    transition: color 0.3s ease;
  }
  
  .footer-social a:hover {
    color: #ffffff; /* Mantener color blanco en hover */
  }
  
  .footer-social a:focus {
    outline: none; /* Eliminar el borde de enfoque */
  }
  
  /* Responsivo */
  @media (max-width: 768px) {
    .footer-container {
      flex-direction: column;
      text-align: center;
    }
  }
  