/* Variables de color */
:root {
    --primary-color: #FFFFFF;
    --text-color: #ffffff;
}
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&family=Lora:wght@400;700&display=swap');

* {
    margin: 0;
    padding: 0;
    font-family: 'Lora', serif; /* Fuente elegante para textos generales */
    
    ::selection {
        background: #00C8C8; /* Color de fondo del texto seleccionado */
        color: #000000; /* Color del texto seleccionado */
    }
}

/* Títulos con fuente más sofisticada */
h1, h2, h3, h4, h5, h6 {
    font-family: 'Playfair Display', serif;
    font-weight: 700;
    line-height: 1.2;
}

/* Tamaños de fuente */
h1 { font-size: 48px; } /* Título principal, impactante */
h2 { font-size: 36px;
color: #000000 !important; } /* Subtítulos importantes */
h3 { font-size: 28px;
    color: #000000 !important; } /* Subtítulos importantes */
 /* Secciones secundarias */
h4 { font-size: 22px; } /* Encabezados internos */
h5 { font-size: 18px; } /* Títulos menores */
h6 { font-size: 16px; } /* Textos de menor jerarquía */

p {
    font-size: 18px; /* Tamaño óptimo para lectura */
    line-height: 1.6; /* Espaciado cómodo */
    color: #333; /* Negro suave para mejor legibilidad */
}

small {
    font-size: 14px;
}

/* Responsive: Ajustes para pantallas más pequeñas */
@media (max-width: 768px) {
    h1 { font-size: 36px; }
    h2 { font-size: 28px; }
    h3 { font-size: 24px; }
    p { font-size: 16px; }
}



/* Estilos header */
/* Contenedor principal del carrusel */
.carousel-container {
    position: relative;
    width: 100%;
    margin: auto;
    overflow: hidden;
    border-radius: 10px;
    padding: 2px;

}

/* Estilos para el texto superpuesto */
.carousel-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    text-align: center;
    width: 80%;
}

/* Fondo semitransparente para mejorar la legibilidad */
.carousel-overlay {
    background: rgba(0, 0, 0, 0.434);
    padding: 20px;
    border-radius: 10px;
}

/* Títulos y descripciones */
.carousel-text h1 {
    font-size: 2.5em;
    color: #ffffff;
    font-weight: 800;
    margin-bottom: 10px;
}

.carousel-description {
    font-size: 1.2em;
    color: #ffffff;
    font-weight: 400;
    line-height: 1.5;
}

/* Imágenes del carrusel */
.carousel-image {
    width: 100%;
    height: 500px;
    object-fit: cover;
    border-radius: 10px;
}

